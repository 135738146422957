<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      Editar Indicador
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!--  key field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Clave"
              rules="required"
            >
              <b-form-group
                label="Clave"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="indicator.clave"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- name Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="indicator.nombre"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import generalData from '@/services/generalData.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  setup() {
    /* SERVICES */
    const {
      fetchIndicator,
      updateIndicator,
    } = generalData()

    // Data
    const indicator = ref({})
    fetchIndicator(router.currentRoute.params, data => {
      indicator.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateIndicator(indicator.value)
        .then(() => {
          router.push({ name: 'apps-indicator-list' })
        })
    }
    /* Validations */
    const indicatorData = ref(JSON.parse(JSON.stringify(indicator.value)))
    const resetclienteData = () => {
      indicatorData.value = JSON.parse(JSON.stringify(indicator))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)
    return {
      // Data
      indicator,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
