import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function generalDataService() {
  const defaultMethods = defaultService('GeneralDatos')
  const {
    throwError,
    throwSuccess,
    toastSuccess,
    // toastError,
  } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
  // ===================================================================|
  //  ConnectionStrings--------------------------------------------------------------------------------------------|
  // ===================================================================|

  const fetchConnectionStrings = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/CadenasConexion`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchConnectionString = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/CadenasConexion/${data.ConnectionId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateConnectionString = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/CadenasConexion/${data.cadenaConexionId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createConnectionString = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/CadenasConexion`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const deleteConnectionString = (data, callback) => {
    axios
      .delete(`${API_URL}/GeneralDatos/CadenasConexion/${data}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const testConnectionString = cadenaConexion => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/CadenasConexion/Probar`, cadenaConexion)
      .then(response => {
        toastSuccess(response.data.mensaje)
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
        throwError(error)
        // toastError(error.data.mensaje)
      })
  })
  // const testConnectionString = (callback, cadenaConexion) => {
  //   axios
  //     .post(`${API_URL}/GeneralDatos/CadenasConexion/Probar/${cadenaConexion}`)
  //     .then(response => {
  //       callback(response.data)
  //     })
  //     .catch(error => throwError(error))
  // }
  // ===================================================================|
  //  ConfitionFilters---------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchConditionFilters = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/CondicionesFiltrado`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchConditionFilter = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/CondicionesFiltrado/${data.ConditionId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createConditionFilter = (data, callback) => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/CondicionesFiltrado`, data)
      .then(response => {
        callback(response.data)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateConditionFilter = (data, callback) => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/CondicionesFiltrado/${data.condicionFiltradoId}`, data)
      .then(response => {
        callback(response.data)
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteConditionFilter = (data, callback) => {
    axios
      .delete(`${API_URL}/GeneralDatos/CondicionesFiltrado/${data}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchConditionFilterType = callback => {
    axios
      .get(`${API_URL}/Opciones/CondicionFiltradoTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchTypeObjectQuery = callback => {
    axios
      .get(`${API_URL}/GeneralDatos/objectoConsulta/Opciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchObjectQuery = callback => {
    axios
      .get(`${API_URL}/GeneralDatos/objectoConsulta/Opciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchColumnType = callback => {
    axios
      .get(`${API_URL}/Opciones/ColumnaTipoDato`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchLogicOperatorType = callback => {
    axios
      .get(`${API_URL}/Opciones/OperadorLogicoTipo`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  // ===================================================================|
  //  QueryObjectColumns--------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchQueryObjects = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/ObjetosConsulta`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchQueryObject = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/ObjetosConsulta/${data.QueryObjectId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createQueryObject = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/ObjetosConsulta`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateQueryObject = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/ObjetosConsulta/${data.objetoConsultaId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const fetchConnectionStringOptions = callback => {
    axios
      .get(`${API_URL}/GeneralDatos/CadenasConexion/Opciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchQueryObjectColumns = (callback, objetoConsultaId) => {
    axios
      .get(`${API_URL}/GeneralDatos/ObjetosConsulta/${objetoConsultaId}/Columnas`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchQueryObjectDatabase = (callback, cadenaConexionId) => {
    axios
      .get(`${API_URL}/GeneralDatos/ObjetosConsultaDeBaseDatos/${cadenaConexionId}`, { params: { limit: 10000 } })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteQueryObject = (data, callback) => {
    axios
      .delete(`${API_URL}/GeneralDatos/ObjetosConsulta/${data}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  Aduana -------------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchCustoms = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/Aduanas`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchCustom = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/Aduanas/${data.AduanaId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateCustom = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/Aduanas/${data.aduanaId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  // ===================================================================|
  //  BondenWarehouse -----------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchBondenWarehouses = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/RecintoFiscal`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchBondenWarehouse = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/RecintoFiscal/${data.recintoFiscalId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateBondenWarehouse = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/RecintoFiscal/${data.id}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  // ===================================================================|
  //  TransportationLines -----------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchTransportationLines = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/LineasTransporte`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createTransportationLines = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/LineasTransporte`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const fetchTransportationLine = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/LineasTransporte/${data.LineaTransporteId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateTransportationLine = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/LineasTransporte/${data.lineaTransporteId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteTransportationLine = data => new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/GeneralDatos/LineaTransporte/${data.lineaTransporteId}`)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const fetchCustomTypesOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/AduanaTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  ActionImprovementProblem-------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchActionImprovementProblems = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/AccionMejoraProblema`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createActionImprovementProblem = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/AccionMejoraProblema`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const fetchActionImprovementProblem = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/AccionMejoraProblema/${data.accionMejoraProblemaId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateActionImprovementProblem = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/AccionMejoraProblema/${data.accionMejoraProblemaId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteActionImprovementProblem = (data, callback) => {
    axios
      .delete(`${API_URL}/GeneralDatos/AccionMejoraProblema/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  Indicator-------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchIndicators = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/Indicador`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createIndicator = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/GeneralDatos/Indicador`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const fetchIndicator = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/Indicador/${data.indicadorId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateIndicator = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/GeneralDatos/Indicador/${data.indicadorId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteIndicator = (data, callback) => {
    axios
      .delete(`${API_URL}/GeneralDatos/Indicador/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  // ===================================================================|
  //  Users --------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchUsersExportExcel = (data, callback) => {
    axios
      .get(`${API_URL}/GeneralDatos/Usuarios/ExportarExcel`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  Bank --------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchBanks = (data, callback) => defaultMethods.fetch('Banco', {}, data, callback)
  const fetchBank = (id, callback) => defaultMethods.fetch('Banco/%{id}', { id }, {}, callback)
  const createBank = (data, callback) => defaultMethods.create('Banco', {}, true, data, callback)
  const updateBank = (id, data, callback) => defaultMethods.update('Banco/%{id}', { id }, true, data, callback)

  // ===================================================================|
  //  ConditionFiltersOptions --------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchIsFromSource = (id, callback) => defaultMethods.fetch('CondicionesFiltradoTipo/%{id}/EsDesdeUnaFuente', { id }, {}, callback)
  const fetchIsPermanent = (id, callback) => defaultMethods.fetch('CondicionesFiltradoTipo/%{id}/EsFijo', { id }, {}, callback)
  const fetchConditionFilterAuxiliar = (id, data, callback) => defaultMethods.fetch('CondicionesFiltrado/%{id}/CondicionAuxiliar', { id }, data, callback)
  const fetchConditionFilterOptions = (data, callback) => defaultMethods.fetch('CondicionFiltradoOpciones', {}, data, callback)
  const fetchConditionFilterOption = (id, callback) => defaultMethods.fetch('CondicionFiltradoOpciones/%{id}', { id }, {}, callback)
  const createConditionFilterOption = (data, callback) => defaultMethods.create('CondicionFiltradoOpciones', {}, true, data, callback)
  const updateConditionFilterOption = (id, data, callback) => defaultMethods.update('CondicionFiltradoOpciones/%{id}', { id }, true, data, callback)
  const deleteConditionFilterOption = (id, callback) => defaultMethods.remove('CondicionFiltradoOpciones/%{id}', { id }, true, callback)

  return {
    // ConnectionStrings
    fetchConnectionStrings,
    fetchConnectionString,
    createConnectionString,
    updateConnectionString,
    deleteConnectionString,
    testConnectionString,
    // ConditionFilters
    fetchConditionFilters,
    fetchConditionFilter,
    createConditionFilter,
    updateConditionFilter,
    deleteConditionFilter,
    // FetchQueryObjects
    fetchQueryObjects,
    fetchQueryObject,
    createQueryObject,
    updateQueryObject,
    deleteQueryObject,
    fetchTypeObjectQuery,
    fetchObjectQuery,
    //  Aduana
    fetchCustoms,
    fetchCustom,
    updateCustom,
    // BondenWarehouse
    fetchBondenWarehouses,
    fetchBondenWarehouse,
    updateBondenWarehouse,
    // TransportationLines
    fetchTransportationLines,
    fetchTransportationLine,
    createTransportationLines,
    deleteTransportationLine,
    updateTransportationLine,
    // ActionImprovementProblem
    fetchActionImprovementProblems,
    createActionImprovementProblem,
    fetchActionImprovementProblem,
    updateActionImprovementProblem,
    deleteActionImprovementProblem,
    // Indicator
    fetchIndicators,
    createIndicator,
    fetchIndicator,
    updateIndicator,
    deleteIndicator,
    // Options
    fetchColumnType,
    fetchConnectionStringOptions,
    fetchConditionFilterType,
    fetchLogicOperatorType,
    fetchQueryObjectColumns,
    fetchQueryObjectDatabase,
    fetchCustomTypesOptions,
    // Users
    fetchUsersExportExcel,
    // Bank
    fetchBanks,
    fetchBank,
    createBank,
    updateBank,
    // FilterConditionOptions
    fetchIsFromSource,
    fetchIsPermanent,
    fetchConditionFilterAuxiliar,
    fetchConditionFilterOptions,
    fetchConditionFilterOption,
    createConditionFilterOption,
    updateConditionFilterOption,
    deleteConditionFilterOption,
  }
}
